import React, {useEffect, useState} from "react";
import logo from "../assets/logo-blanc-1.png";
import PhoneInput from "react-phone-number-input";
import {E164Number, isValidPhoneNumber} from "libphonenumber-js";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import profile from "../assets/icons/profile.png";
import lock from "../assets/icons/lock.png";
import mail from "../assets/icons/message.png";
import {useNavigate} from "react-router-dom";
import menu from "../assets/menu.png";
import contact from "../assets/bg/contact.png";
import validator from 'validator';
export default function AccountCreation({setSteps}: { setSteps: any }) {
    const {t} = useTranslation();

    const [value, setValue] = useState<undefined | E164Number>(localStorage.getItem('number') || '');
    const [name, setName] = useState(localStorage.getItem('name') || '');
    const [email, setEmail] = useState('');
    const [emailConfirm, setEmailConfirm] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isPasswordVisible2, setPasswordVisible2] = useState(false);
    const navigate = useNavigate()

    const togglePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(!isPasswordVisible2);
    };

    const nextStep = () => {
        if (name !== '' && typeof value !== 'undefined') {
            if (isValidPhoneNumber(value?.toString() as string)) {
                if (password !== '') {
                    if(!validator.isEmail(email)) {
                        toast.error('L\'email invalide', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        return;
                    }
                    if(email !== emailConfirm){
                        toast.error('L\'email n\'est pas identique', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        return
                    }

                    if (password === confirm) {
                        const requestOptions = {
                            method: 'PUT',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                id: localStorage.getItem('user_key'),
                                name: name,
                                email: email,
                                password: password,
                                number: value
                            })
                        };

                        fetch(`${process.env.REACT_APP_API_URL}/users/${localStorage.getItem('user_key')}`, requestOptions)
                            .then(response => response.json())
                            .then(data => {
                                if (data.success === true) {
                                    localStorage.setItem('user', JSON.stringify({
                                        name: name,
                                        email: email,
                                        password: password,
                                        number: value
                                    }))
                                    localStorage.setItem('first_launch', 'false')
                                    localStorage.setItem('logged', 'true')
                                    navigate('/pack-chooser');
                                } else {
                                    toast.error('Une erreur est survenu, veuillez reessayer', {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }
                            }).catch((e) => toast.error('Une erreur est survenu, veuillez reessayer', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        }))
                    } else {
                        toast.error('Le mot de passe n\'est pas identique', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                } else {
                    toast.error('Veuillez definir un mot de passe', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

            }
        }
    }

    return (
        <div className='container p-4 col-12 col-md-4 ms-auto me-auto roboto-regular overflow-y-scroll'
             style={{
                 marginBottom: '100px',
             }}
        >
            <div className='position-absolute top-0 start-0 ms-2'>
                <img
                    src={logo}
                    className="img-fluid mt-3 mb-3"
                    style={{
                        width: '25%'
                    }} alt=''/>
            </div>
            <div className=''>
                <img
                    src={menu}
                    className="img-fluid position-absolute top-0 end-0 me-2"
                    style={{
                        width: '25px',
                        marginTop: '20px',
                    }} alt=''/>
            </div>
            <div className='text-center position-absolute top-0'>
                <img
                    src={contact}
                    className="img-fluid"
                    style={{

                        marginTop: '20px',
                    }} alt=''/>
            </div>
            <div style={{
                marginTop: '225px'
            }} className='text-center card border-0 shadow-sm p-3'>
                <h3 className='roboto-bold mt-5 dark-blue text-start'>
                    Création du compte
                </h3>
                <div>
                    <div className='input-group'>
                        <span
                            className="input-group-text border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0 mb-4"
                            id="basic-addon1">
                            <img src={profile} style={
                                {
                                    width: '15px'
                                }
                            }/>
                        </span>
                        <input placeholder={t('form-elements.last_first')}
                               type='text'
                               value={name}
                               onChange={event => setName(event.target.value)}
                               className='form-control mb-4 border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0'/>
                    </div>
                    <div className='input-group'>
                        <span
                            className="input-group-text mb-4 border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                            id="basic-addon1">
                            <img src={mail} style={
                                {
                                    width: '15px'
                                }
                            }/>
                        </span>
                        <input placeholder='email'
                               type='email'
                               className='form-control mb-4 border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0'
                               value={email}
                               onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                    <div className='input-group'>
                        <span
                            className="input-group-text mb-4 border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                            id="basic-addon1">
                            <img src={mail} style={
                                {
                                    width: '15px'
                                }
                            }/>
                        </span>
                        <input placeholder='Confirmation email'
                               type='email'
                               className='form-control mb-4 border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0'
                               value={emailConfirm}
                               onChange={(event) => setEmailConfirm(event.target.value)}
                        />
                    </div>
                    <PhoneInput
                        international={false}
                        defaultCountry="FR"
                        countries={["FR"]}
                        country="FR"
                        className='form-control mb-4 border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0'
                        placeholder={t('form-elements.number')}
                        onChange={setValue}/>

                    <h3 className='roboto-bold mt-5 dark-blue text-start'>
                        Création du mot de passe
                    </h3>
                    <div className="input-group mb-4">
                        <span
                            className="input-group-text border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                            id="basic-addon1">
                            <img src={lock} style={
                                {
                                    width: '15px'
                                }
                            }/>
                        </span>
                        <input
                            placeholder="Mot de passe"
                            type={isPasswordVisible ? 'text' : 'password'}
                            className="form-control border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                        <span
                            className="input-group-text border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                            onClick={togglePasswordVisibility}
                            style={{cursor: 'pointer'}}>
                            {isPasswordVisible ? <i className='fa fa-eye color-grey'></i> :
                                <i className='fa fa-eye-slash color-grey'></i>}
                          </span>
                    </div>
                    <div className="input-group ">
                        <span
                            className="input-group-text border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                            id="basic-addon1">
                            <img src={lock} style={
                                {
                                    width: '15px'
                                }
                            }/>
                        </span>
                        <input
                            placeholder="Confirmation de mot de passe"
                            type={isPasswordVisible2 ? 'text' : 'password'}
                            className="form-control border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                            value={confirm}
                            onChange={(event) => setConfirm(event.target.value)}
                        />
                        <span
                            className="input-group-text border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                            onClick={togglePasswordVisibility2}
                            style={{cursor: 'pointer'}}>
                            {isPasswordVisible2 ? <i className='fa fa-eye color-grey'></i> :
                                <i className='fa fa-eye-slash color-grey'></i>}
                          </span>
                    </div>
                    <div className='fixed-bottom bottom-0 text-center px-5 mb-5' style={
                        {
                            height: '75px'
                        }
                    }>
                        <button
                            className='btn mt-5 btn-outline-dark bg-dark-blue w-100 text-white dark-blue-border shadow-sm'
                            onClick={nextStep}>
                            {t('buttons.validate')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}