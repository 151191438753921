import logo from "../assets/logo-blanc-1.png";
import bg from "../assets/bg-home.jpg";
import menu from "../assets/menu.png";
import contact from "../assets/bg/contact.png";
import React, {useEffect} from "react";
import {useAddToHomescreenPrompt} from "../hooks/InstallPrompt";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function InstallFirst() {
    const {t} = useTranslation();
    const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches
    const navigate = useNavigate();
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod|mac/.test(userAgent);
    }
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const installAndContinue = (e: React.MouseEvent) => {
        if (!isInStandaloneMode() && !isIos()) {
            promptToInstall();
            return;
        }

        // @ts-ignore
        document.getElementById('installButton').innerHTML = 'Installation terminée.\n' +
            'Fermes ce navigateur.'

        //navigate('/');
    }

    useEffect(() => {
        if (isInStandaloneMode()) {
            navigate('/');
        }
    }, []);

    return (
        <div className='roboto-regular' style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover'
        }}>
            <div className='container p-4 col-12 col-md-4 ms-auto me-auto'>
                <div className='position-absolute top-0 start-0 ms-2'>
                    <img
                        src={logo}
                        className="img-fluid mt-3 mb-3"
                        style={{
                            width: '25%'
                        }} alt=''/>
                </div>
                <div className=''>
                    <img
                        src={menu}
                        className="img-fluid position-absolute top-0 end-0 me-2"
                        style={{
                            width: '25px',
                            marginTop: '20px',
                        }} alt=''/>
                </div>
                <div className='text-center'>
                    <h2 className='mt-5 pt-5'>Pour installer</h2>
                </div>
                <div>
                    {!isIos() && <div className='card border-0 shadow-sm'>
                        <div className='card-body'>
                            <div className='text-center text-blue'>pour <strong>
                                Android
                            </strong></div>
                            {!isInStandaloneMode() && (
                                <div className='text-center mt-3' id='installButton'>
                                    <button className='btn btn-danger shadow-sm text-white w-100'
                                            onClick={e => installAndContinue(e)}>
                                        {t('installation.install')}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>}
                    {isIos() && <div>
                        <div className='card border-0 shadow-sm mt-4'>
                            <div className='card-body'>
                                <div className='text-blue text-center'>
                                    Si <strong>Safari</strong> est ton navigateur par défaut
                                </div>
                                <div>
                                    clique sur:<br/>
                                    <i className="fa-solid fa-arrow-up-from-bracket me-3"></i> Partager
                                </div>
                                <div className='mt-3 fs-6'>
                                    <i className="fa-solid fa-circle-plus me-3"></i> Sur l'écran d'accueil
                                </div>
                            </div>
                        </div>
                        <div className='card border-0 shadow-sm mt-4'>
                            <div className='card-body'>
                                <div className='text-blue text-center'>
                                    <strong>Si navigateur par défaut est autre que Safari</strong><br/><br/>
                                    <i>
                                        copie l'url suivante :<br/>
                                        <a href='https://app.urgent-assistance.com'>app.urgent-assistance.com</a>
                                        <br/>
                                        dans Safari
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}