import logo from "../assets/logo-blanc-1.png";
import mission from "../assets/mission.png";
import slide1 from "../assets/slideshow/slide-1.png";
import slide2 from "../assets/slideshow/slide-2.png";
import slide3 from "../assets/slideshow/slide-3.png";
import menu from "../assets/menu.png";
import contact from "../assets/bg/contact.png";
import React from "react";
import 'react-slideshow-image/dist/styles.css'
import {Slide} from "react-slideshow-image";
import {useNavigate} from "react-router-dom";

const ChoosePack = () => {
    const navigate = useNavigate();
    const images = [
        slide1,
        slide2,
        slide3,
    ];

    return (
        <div className='container p-4 col-12 col-md-4 ms-auto me-auto mb-5'>
            <div className='position-absolute top-0 start-0 ms-2'>
                <img
                    src={logo}
                    className="img-fluid mt-3 mb-3"
                    style={{
                        width: '25%'
                    }} alt=''/>
            </div>
            <div className=''>
                <img
                    src={menu}
                    className="img-fluid position-absolute top-0 end-0 me-2"
                    style={{
                        width: '25px',
                        marginTop: '20px',
                    }} alt=''/>
            </div>
            <div className='text-center'>
                <img
                    src={contact}
                    className="img-fluid"
                    style={{

                        marginTop: '20px',
                    }} alt=''/>
                <h3 className='roboto-bold mt-5 dark-blue'>
                    Fonctionalités
                </h3>
                <Slide>
                    <div className="each-slide-effect">
                        <div style={{'backgroundImage': `url(${images[0]})`}}>
                        </div>
                    </div>
                    <div className="each-slide-effect">
                        <div style={{'backgroundImage': `url(${images[1]})`}}>
                        </div>
                    </div>
                    <div className="each-slide-effect">
                        <div style={{'backgroundImage': `url(${images[2]})`}}>
                        </div>
                    </div>
                </Slide>
                <h3 className='roboto-bold mt-3 dark-blue'>
                    Notre mission
                </h3>
                <img
                    src={mission}
                    className="img-fluid"
                    style={{

                        marginTop: '20px',
                    }} alt=''/>
                <h3 className='roboto-bold mt-3 text-danger text-start'>
                    Combattre
                </h3>
                <h3 className='roboto-bold dark-blue text-start'>
                    l'insécurité et l'isolement
                </h3>
                <div className='card border-0 shadow-sm'>
                    <div className='card-body'>
                        <div className='text-center fs-5 text-blue fw-bold mb-3'>Notre mission</div>
                        <p className='text-start'>
                            <strong>
                                Faire de ta sécurité notre priorité
                            </strong>
                            <br/>
                            Violences urbaines ou familiales, problèmes de santé soudains ou chroniques, l'isolement est
                            devenu un problème majeur de notre société.
                            Nous nous sommes donné pour mission d'améliorer la sécurité des personnes avec des
                            technologies innovantes, brevetées et reconnues notammenet par le Sceau de l'Excellence
                            de l'Union Européenne en 2019.
                        </p>
                    </div>
                </div>
                <div className='card border-0 shadow-sm mt-3'>
                    <div className='card-body'>
                        <div className='text-center fs-5 text-blue fw-bold mb-3'>Nos valeurs</div>
                        <p className='text-start'>
                            <strong>
                                Pragmatisme
                            </strong>
                            <br/>
                            Notre pratique repose sur l'expérience de terrain et le contact quotidien avec des personnes
                            en situation d'urgence ou santé ou sécurité.
                            <br/>
                            <strong>
                                Écoute
                            </strong>
                            <br/>
                            Chaque jour, c'est par une écoute attentive que nous comprenons mieux et très pratiquement
                            les besoins de sécurité et de sérénité.
                            <br/>
                            <strong>
                                Respect
                            </strong>
                            <br/>
                            Nous reconnaisson à toute personne le respect inconditionnel, quel que soit son âge,
                            sa condition sociale, son style de vie, sa religion ou sa couleur de peau.
                        </p>
                    </div>
                </div>
                <div className='card border-0 shadow-sm mt-3'>
                    <div className='card-body'>
                        <div className='text-center fs-5 text-blue fw-bold mb-3'>Notre mission</div>
                        <p className='text-start'>
                            <strong>
                                Faire de ta sécurité notre priorité
                            </strong>
                            <br/>
                            Violences urbaines ou familiales, problèmes de santé soudains ou chroniques, l'isolement est
                            devenu un problème majeur de notre société.
                            Nous nous sommes donné pour mission d'améliorer la sécurité des personnes avec des
                            technologies innovantes, brevetées et reconnues notammenet par le Sceau de l'Excellence
                            de l'Union Européenne en 2019.
                        </p>
                    </div>
                </div>
                <button
                    className='btn mt-5 btn-danger w-100 text-white shadow-sm'
                    onClick={() => navigate('/pack-chooser')}
                >
                    Choisir le pack SMS
                </button>
                <div className='text-center fw-bold text-blue mt-4 mb-5' onClick={() => navigate('/')}>
                    Plus tard
                </div>
                <div style={{
                    height: '35px',
                }}>
                </div>
            </div>

        </div>
    );
}

export default ChoosePack;