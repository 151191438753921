import bg from "../assets/bg-home.jpg";
import logo from "../assets/logo-blanc-1.png";
import menu from "../assets/menu.png";
import security from "../assets/securite.jpg";
import serenity from "../assets/serenity.jpg";
import React from "react";
import contact from "../assets/bg/contact.png";
import {useNavigate} from "react-router-dom";

const PackChooser = () => {
    const navigate = useNavigate();

    return (
        <div style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover'
        }}>
            <div className='position-absolute top-0 start-0 ms-2'>
                <img
                    src={logo}
                    className="img-fluid mt-3 mb-3"
                    style={{
                        width: '25%'
                    }} alt=''/>
            </div>
            <div className=''>
                <img
                    src={menu}
                    className="img-fluid position-absolute top-0 end-0 me-2"
                    style={{
                        width: '25px',
                        marginTop: '20px',
                    }} alt=''/>
            </div>
            <div className='text-center'>
                <img
                    src={contact}
                    className="img-fluid"
                    style={{

                        marginTop: '20px',
                    }} alt=''/>
            </div>
            <h3 className='roboto-bold mt-3 dark-blue text-center'>
                Choisi ton Pack
            </h3>
            <div className='text-center row ps-3 pe-3'>
                <div className='col-6'>
                    <img
                        onClick={() => navigate('/checkout/1')}
                        src={security}
                        className="img-fluid"
                        style={{

                            marginTop: '20px',
                        }} alt=''/>
                </div>
                <div className='col-6'>
                    <img
                        onClick={() => navigate('/checkout/2')}
                        src={serenity}
                        className="img-fluid"
                        style={{

                            marginTop: '20px',
                        }} alt=''/>
                </div>
            </div>
            <div style={{
                height: '35px',
            }} className='text-center fw-bold text-blue mt-4 mb-5' onClick={() => navigate('/')}>
                Plus tard
            </div>
        </div>
    )
}

export default PackChooser;