import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AccountCreation from "../components/AccountCreation";
import bg from "../assets/bg-home.jpg";

export default function Install() {
    const [steps, setSteps] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const first = localStorage.getItem('first_launch');
        if (first !== null) {
            navigate('/');
        }
    }, [])

    return (
        <div style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover'
        }}>
            {steps === 1 && <AccountCreation setSteps={setSteps}/>}
        </div>
    )
}