import React, {useEffect, useRef, useState} from "react";
import {useGeolocated} from "react-geolocated";
import {toast} from "react-toastify";
import logo from "../assets/picto.png";
import qrcode from "../assets/qrcode.png";
import menu from "../assets/menu.png";
import pin from "../assets/pin.png";
import insta from "../assets/icons/insta.png";
import tiktok from "../assets/icons/tiktok.png";
import linkedin from "../assets/icons/linkedin.png";
import facebook from "../assets/icons/fb.png";
import silence from "../assets/silence.mp3";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ButtonGroup, Dropdown} from "react-bootstrap";
import logo2 from "../assets/logo-blanc-1.png";
import bg from "../assets/bg-home.jpg";

type Location = {
    latitude: number;
    longitude: number;
};
const Main = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [background] = useState<string>(localStorage.getItem('background') || 'null')
    const [opacity] = useState<string>(localStorage.getItem('opacity') || '1')
    const [user] = useState<any | undefined>(
        JSON.parse(localStorage.getItem('user') || '[]')
    );
    const [previousLocation, setPreviousLocation] = useState<Location | null>(null);
    const {coords, isGeolocationAvailable, isGeolocationEnabled} =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 15000,
            watchPosition: true,
        });
    const [launching, setLaunching] = useState(false);
    const [launched, setLaunched] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [disconnected, setDisconnected] = useState<boolean>(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches;
    const togglePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    const handleSendSMS = (e: React.MouseEvent<HTMLImageElement>) => {
        sendAlarm()
    }

    const sendAlarm = () => {
        const correspondents = localStorage.getItem('correspondents');
        if (correspondents === null) {
            navigate('/correspondents');
            return;
        }
        const isEmpty =
            !user || // null or undefined
            (typeof user === "object" && Object.keys(user).length === 0) || // empty object
            (Array.isArray(user) && user.length === 0);
        if (isEmpty) {
            navigate('/my-account');
            return;
        }

        const userKey = localStorage.getItem('user_key');
        if (userKey === null) {
            const corres = JSON.parse(correspondents)
            window.location.href = `tel:${corres[0].number}`
            setLaunching(false);
            return;
        }

        if (coords?.latitude !== null) {
            setLaunching(true);
            fetch(`${process.env.REACT_APP_API_URL}/alarms/user/${localStorage.getItem('user_key')}`)
                .then(response => response.json())
                .then(data => {
                        if (data.success == true) {
                            const requestOptions = {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify({
                                    name: user.name,
                                    number: user.number,
                                    correspondents: JSON.parse(correspondents),
                                    user_id: localStorage.getItem('user_key'),
                                    latitude: coords?.latitude,
                                    longitude: coords?.longitude,
                                    tracking_time: localStorage.getItem('tracking_time'),
                                })
                            };
                            fetch(`${process.env.REACT_APP_API_URL}/alarms`, requestOptions)
                                .then(response => response.json())
                                .then(data => {
                                    const corres = JSON.parse(correspondents)
                                    setLaunched(true);
                                    setLaunching(false);
                                    window.location.href = `tel:${corres[0].number}`
                                    setTimeout(() => {
                                        setLaunched(false)
                                    }, 3000)

                                    if (data.success !== true) {
                                        toast.error(data.error, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });
                                        if (data?.reset !== undefined) {
                                            //localStorage.clear();
                                            navigate('/install');
                                            return;
                                        }
                                    }

                                })
                                .catch(() => {

                                });
                        } else {
                            const corres = JSON.parse(correspondents)
                            window.location.href = `tel:${corres[0].number}`
                            setLaunching(false);
                            /*
                            toast.error(data.error, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });*/
                        }
                    }
                )
                .catch(() => {
                    setLaunching(false);
                    toast.error("Clé utilisateur invalide", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    navigate('/install');
                });
        } else {
            toast.error('Localisation impossible', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const sendLocationUpdate = async (location: Location) => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: localStorage.getItem('user_key'),
                latitude: location.latitude,
                longitude: location.longitude
            })
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/update-location`, requestOptions)
            .catch((e) => {

            })
    };
    const handlePassword = () => {
        if (user.password !== password) {
            toast.error('Mot de passe érroné', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            setShowModal(false)
            setPassword('')
            localStorage.setItem('logged', 'true')
            setDisconnected(true)
        }
    }

    const logout = () => {
        localStorage.removeItem('logged')
        setShowModal(true)
        setDisconnected(true)
    }

    const usePageVisibility = (): boolean => {
        const [isVisible, setIsVisible] = useState(!document.hidden);

        useEffect(() => {
            const handleVisibilityChange = () => setIsVisible(!document.hidden);

            document.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }, []);

        return isVisible;
    };

    const isVisible = usePageVisibility();

    const share = async () => {
        const shareData = {
            title: "URGENT Assistance",
            text: "Votre compagnion",
            url: "https://urgent-assistance.com",
        };

        try {
            await navigator.share(shareData);

        } catch (err) {
            console.log(err)
        }
    }

    const sendUserPassword = () => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                password: user.password
            })
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/password/${localStorage.getItem('user_key')}`, requestOptions)
            .catch((e) => {

            }).finally(() => toast.success('Votre mot de passe à été envoyer par email', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        }))
    }

    const openLink = (url: string) => {
        window.open(url, "_blank");
    };

    useEffect(() => {
        if (isVisible) {
            if ('clearAppBadge' in navigator) {
                (navigator as any).clearAppBadge().catch(console.error);
            } else {
                console.log('Badge cleared (fallback)');
            }
        } else {
            if ('setAppBadge' in navigator) {
                (navigator as any).setAppBadge(1).catch(console.error);
            } else {
                console.log('Badge set (fallback)');
            }
        }
    }, [isVisible]);

    useEffect(() => {
        if (localStorage.getItem('logged') !== null) {
            if ('Notification' in window) {
                const permissionRequest = Notification.requestPermission();
                // For modern browsers supporting Promises
                permissionRequest
                    .then((result) => {
                        console.log('Permission result:', result);
                    })
                    .catch((error) => {
                        console.error('Error requesting permission:', error);
                    });
            } else {
                console.error('Notifications are not supported in this browser.');
            }
        }
        const searchParams = new URLSearchParams(window.location.search);
        const keyValue = searchParams.get("key");

        if (keyValue !== null) {
            localStorage.setItem('user_key', keyValue)
        }

        if (!isInStandaloneMode()) {
            navigate('/install-first');
            return;
        }

        if (Object.keys(user).length === 0) {
            navigate('/install');
            return;
        }

        if (localStorage.getItem('first_launch') === null) {
            navigate('/install');
            return;
        }

        const screen = localStorage.getItem('screen_time');
        if (screen !== null) {
            const audio = new Audio();
            audio.src = silence;
            audio.loop = true;
            audioRef.current = audio;

            // Attempt to play the audio
            audio.play()
                .then(() => {
                    console.log("Audio playback started successfully.");
                })
                .catch((err) => {
                    console.error("Audio playback failed:", err);
                });

            // Cleanup on unmount
            return () => {
                if (audioRef.current) {
                    console.log("Cleaning up audio");
                    audioRef.current.pause();
                    audioRef.current.src = ""; // Clear the source
                    audioRef.current = null; // Clear the reference
                }
            };
        }

        const logged = localStorage.getItem('logged');
        if (logged == null) {
            setShowModal(true)
            setDisconnected(false)
        }

    }, []);

    useEffect(() => {
        if (coords) {
            const currentLocation = {latitude: coords.latitude, longitude: coords.longitude};
            if (
                !previousLocation ||
                previousLocation.latitude !== currentLocation.latitude ||
                previousLocation.longitude !== currentLocation.longitude
            ) {
                sendLocationUpdate(currentLocation);
                setPreviousLocation(currentLocation);
            }
        }
    }, [coords, previousLocation]);

    return !isGeolocationAvailable ? (
        <div className="container text-center mt-5 pt-5 vh-100">
            <div className="bg-danger p-5 rounded">
                {t('messages.geolocation-error')}
            </div>
        </div>
    ) : !isGeolocationEnabled ? (
        <div className="container text-center mt-5 pt-5 vh-100">
            <div className="bg-danger p-5 rounded">
                {t('messages.activate-geolocation')}
            </div>
        </div>
    ) : (
        <div className={!showModal ? `container col-12 col-md-4 ms-auto me-auto pt-5 roboto-regular` : ''}
             id='main-backgound'
             style={background != 'null' ? {
                 backgroundImage: `url(${background})`,
                 backgroundSize: 'cover'
             } : {
                 backgroundImage: `url(${bg})`,
                 backgroundSize: 'cover'
             }}>
            {!showModal && <div>
                <div onDoubleClick={handleSendSMS} className="d-flex justify-content-center pt-5" id='main-block'
                     style={{
                         marginTop: '150px'
                     }}>
                    <img

                        src={logo}
                        className="img-fluid"
                        style={background != 'null' ? {
                            width: '80%',
                            opacity: `${opacity}`,
                            marginTop: '5rem'
                        } : {
                            width: '75%',
                            marginTop: '5rem',
                            opacity: `${opacity}`,
                        }} alt=''/>
                </div>
                <div className='position-absolute top-0 start-0 mb-1' id='location-button' style={{
                    width: '75px',
                    marginTop: '-50px'
                }}>
                    <Link to='/my-location'>
                        <img
                            src={pin}
                            className="img-fluid mt-5"
                            style={{
                                opacity: `${opacity}`,
                            }} alt=''/>
                    </Link>
                </div>
                <div className='text-center mt-5'>
                    {launched && (
                        <div className='text-success'>
                            <i className="fa-solid fa-circle-check" style={{
                                fontSize: '150px'
                            }}></i>
                            <h3 className='mt-2'></h3>
                        </div>
                    )}
                </div>
                <div className="position-absolute top-0 end-0 mb-3" style={{
                    marginRight: '2rem',
                    marginTop: '-50px'
                }}>
                    <div className="d-flex justify-content-center mt-5">
                        <Dropdown as={ButtonGroup} drop="up-centered">
                            <Dropdown.Toggle as="div" variant="secondary" id="dropdown-custom-components">
                                <img
                                    src={menu}
                                    className="img-fluid mt-4 ms-2"
                                    alt="menu"
                                    style={{
                                        cursor: 'pointer',
                                        opacity: `${opacity}`,
                                        width: '40px'
                                    }} // Optional: adds pointer cursor on hover
                                />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-fullscreen">
                                <Dropdown.Item eventKey="1" className='pt-2 pb-2' href='#/my-account'>
                                    Mon compte
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="2" className='pt-2 pb-2' href='#/correspondents'>
                                    Mes contacts
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="3" className='pt-2 pb-2' href='#/background'>
                                    Mes fonds d'écran
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="4" className='pt-2 pb-2' href='#/notice'>
                                    Le guide pratique
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="90" className='pt-2 pb-2' href='#/notice-two'>
                                    La notice d'utilisation
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="5" className='pt-2 pb-2' href='#/legal-mentions'>
                                    Les mentions légales
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="7" className='pt-2 pb-2' onClick={logout}>
                                    <span className='text-danger'>Se déconnecter</span>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="8" className='pt-2 pb-2'>
                                    <hr/>
                                    <strong className='fst-italic'>Partage avec tes amis</strong>
                                    <div className='row text-center'>
                                        <div className='col-6'>
                                            <span>
                                                <img
                                                    src={qrcode}
                                                    className="img-fluid mt-1"
                                                    width="50px"
                                                    alt=''/>
                                            </span>
                                        </div>
                                        <div className='col-6'>
                                            <i
                                                className="fa-solid fa-share-nodes mt-2 dark-blue"
                                                style={{
                                                    fontSize: '50px'
                                                }}
                                                onClick={share}
                                            ></i>
                                        </div>
                                        <div className='col-6'></div>
                                    </div>
                                    <div className='row text-center'>
                                        <div className='col-3'>
                                            <span>
                                                <img
                                                    onClick={() => openLink('https://www.instagram.com/urgentassistance')}
                                                    src={insta}
                                                    className="img-fluid mt-1"
                                                    width="50px"
                                                    alt=''/>
                                            </span>
                                        </div>
                                        <div className='col-3'>
                                            <span>
                                                <img
                                                    onClick={() => openLink('https://www.tiktok.com/@urgentassistance2008')}
                                                    src={tiktok}
                                                    className="img-fluid mt-1"
                                                    width="50px"
                                                    alt=''/>
                                            </span>
                                        </div>
                                        <div className='col-3'>
                                            <span>
                                                <img
                                                    onClick={() => openLink(' https://www.linkedin.com/in/active-business-83886630a')}
                                                    src={linkedin}
                                                    className="img-fluid mt-1"
                                                    width="100px"
                                                    alt=''/>
                                            </span>
                                        </div>
                                        <div className='col-3'>
                                            <span>
                                                <img
                                                    onClick={() => openLink('https://www.facebook.com/profile.php?id=61573613091836')}
                                                    src={facebook}
                                                    className="img-fluid mt-1"
                                                    width="50px"
                                                    alt=''/>
                                            </span>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>}

            {showModal && <div className='vh-100 bg-white align-middle p-5'>
                {!disconnected && (
                    <div className='vh-100'>
                        <div className='w-100'>
                            <div className='text-center'>
                                <img
                                    src={logo2}
                                    className="img-fluid mt-3 mb-5"
                                    style={{
                                        width: '45%'
                                    }} alt=''/>
                            </div>
                            <div className='fixed-bottom bottom-0 bg-white text-center px-5 pt-3 mb-5'>
                                <div className="text-center mt-5">
                                    <h5 className="modal-title mb-3">
                                        Bonjour<br/>
                                        {user.name}
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <div className="input-group mb-3">
                                        <input
                                            placeholder="Mot de passe"
                                            type={isPasswordVisible ? 'text' : 'password'}
                                            className="form-control border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                                            value={password}
                                            onChange={(event) => setPassword(event.target.value)}
                                        />
                                        <span
                                            className="input-group-text border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                                            onClick={togglePasswordVisibility}
                                            style={{cursor: 'pointer'}}>
                            {isPasswordVisible ? <i className='fa fa-eye color-grey'></i> :
                                <i className='fa fa-eye-slash color-grey'></i>}
                          </span>
                                    </div>
                                    <div className='fst-italic fs-6 text-center text-blue pointer-event mb-5'
                                         onClick={sendUserPassword}>
                                        Mot de passe oublié
                                    </div>
                                    <div className='mt-4 text-center text-danger fst-italic'>
                                        Pense à garder ton application en 1er plan pour une meilleure protection
                                    </div>
                                    <div className='text-center mt-3'>
                                        <button className='btn bg-dark-blue text-white dark-blue-border w-100'
                                                onClick={handlePassword}>
                                            {t('buttons.validate')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {disconnected && (
                    <div className='text-center'>
                        <div className='mb-5 pb-5'>
                            <img
                                src={logo2}
                                className="img-fluid mt-3 mb-3"
                                style={{
                                    width: '45%'
                                }} alt=''/>
                        </div>
                        <div className="modal-body text-center mt-5 pt-5">
                            {user.name}<br/>
                            vous êtes déconnecté<br/>
                            <i>À bientôt</i>
                        </div>
                    </div>
                )}
            </div>}
        </div>
    )
}

export default Main;